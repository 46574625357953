"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _Pagination = _interopRequireDefault(require("@/components/Pagination"));
var _supplier = require("@/api/supplier");
var _store = require("@/api/store");
var _category = require("@/api/category");
var _rawMaterial = require("@/api/rawMaterial");
var _vueSelect = _interopRequireDefault(require("vue-select"));
require("vue-select/src/scss/vue-select.scss");
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
// import i18n from '@/i18n'
var _default = {
  name: 'AddItemDialog',
  components: {
    vSelect: _vueSelect.default,
    Pagination: _Pagination.default
  },
  props: {
    storeId: {
      type: String,
      required: true
    },
    dialogFormVisible: {
      type: Boolean,
      default: false
    },
    existingItems: {
      // rawMaterials
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      suppliers: [],
      stores: [],
      listQuery: {
        page: 1,
        limit: 10,
        supplierId: null,
        categoryId: null,
        storeId: null,
        keyword: null
      },
      categories: [],
      selectedRawMaterials: [],
      rawMaterials: null
    };
  },
  computed: {
    visible: {
      get() {
        return this.dialogFormVisible;
      },
      set(val) {
        this.$emit('update:dialogFormVisible', val);
      }
    }
  },
  watch: {
    store() {
      this.listQuery.storeId = this.storeId;
    }
  },
  async created() {
    // this.role = this.newDuty.roleId
    this.listQuery.storeId = this.storeId;
    if (!this.supplierId) {
      this.supplierId = this.listQuery.supplierId;
    }
    (0, _supplier.getSuppliers)({
      perPage: 999
    }).then(supplierResponse => {
      this.suppliers = supplierResponse.data;
    });
    (0, _store.getStores)({
      perPage: 999
    }).then(storeResponse => {
      this.stores = storeResponse.data;
    });
    (0, _category.getCategories)({
      perPage: 999
    }).then(categoryResponse => {
      this.categories = categoryResponse.data;
    });
    this.fetchData();
  },
  methods: {
    fetchData() {
      (0, _rawMaterial.getRawMaterials)({
        ...this.listQuery,
        storeId: this.storeId
      }).then(response => {
        this.rawMaterials = response.data.filter(rawMaterial => this.existingItems.findIndex(item => item.uuid === rawMaterial.uuid));
        this.total = response.meta.total;
      });
    },
    handleSelect(rawMaterial) {
      let found = false;
      this.selectedRawMaterials.forEach(selectedRawMaterial => {
        if (selectedRawMaterial.uuid === rawMaterial.uuid) {
          found = true;
        }
      });
      if (!found) {
        this.selectedRawMaterials.push(rawMaterial);
      }
      // this.$emit('complete', rawMaterial)
      // this.$emit('update:dialogFormVisible', false)
    },

    handleIsSelected(rawMaterial) {
      return !!this.selectedRawMaterials.find(selectedRawMaterial => selectedRawMaterial.uuid === rawMaterial.uuid);
    },
    handleDeselect(rawMaterial) {
      this.selectedRawMaterials = this.selectedRawMaterials.filter(selectedRawMaterial => selectedRawMaterial.uuid !== rawMaterial.uuid);
    },
    handleSubmit() {
      this.$emit('complete', this.selectedRawMaterials);
      this.$emit('update:dialogFormVisible', false);
    },
    handleCancel() {
      this.$emit('update:dialogFormVisible', false);
    }
  }
};
exports.default = _default;