"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _errorParse = require("@/utils/error-parse");
var _elementUi = require("element-ui");
var _InputNumber = _interopRequireDefault(require("@/components/InputNumber"));
var _moomoomember = require("@/api/moomoomember");
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    ElInputNumber: _InputNumber.default
  },
  data() {
    return {
      loading: false,
      currentUser: null,
      moomoomember: {
        id: null,
        name: '',
        email: '',
        mobilephone: '',
        password: '',
        password_confirmation: '',
        activities: []
      },
      activity: {
        activity: 'Drink'
      },
      buttonText: 'Add',
      hrPerMonth: 0,
      rules: {
        name: {
          required: true,
          message: 'Name is required'
        },
        email: {
          required: true,
          message: 'Email is required'
        },
        activity: {
          required: true,
          message: 'Choose Activity'
        },
        mobilephone: {
          required: true,
          message: 'Mobile is required'
        }
      },
      isEdit: false
    };
  },
  computed: {
    point: {
      get() {
        return this.moomoomember.point ? this.moomoomember.point.point : 0;
      },
      set(val) {
        if (this.moomoomember.point) {
          this.moomoomember.point.point = val;
        }
      }
    }
  },
  async created() {
    if (this.$route.params.id) {
      this.buttonText = 'Apply';
      this.isEdit = true;
      this.fetchData();
    } else {
      this.buttonText = 'Add';
      this.$set(this.rules, 'password', [{
        required: true,
        message: 'Password is required',
        trigger: 'blur'
      }, {
        min: 6,
        message: 'Password can not be less than 6 digits',
        trigger: 'blur'
      }]);
    }
  },
  methods: {
    async fetchData() {
      this.loading = true;
      (0, _moomoomember.getMOOMOOMember)(this.$route.params.id).then(response => {
        this.moomoomember = response.data;
        this.moomoomember.point = 0;
      }).finally(() => {
        this.loading = false;
      });
    },
    addActivity() {
      this.$refs.activity.validate(valid => {
        if (!valid) {
          return false;
        } else {
          this.loading = true;
          if (this.$route.params.id) {
            (0, _moomoomember.addActivity)({
              id: this.moomoomember.passkit,
              activity: this.activity.activity
            }).then(() => {
              // @TODO update member
              this.fetchData();
            }).catch(err => {
              (0, _elementUi.Message)({
                message: (0, _errorParse.parseError)(err) || 'Update failed, please retry.',
                type: 'error',
                duration: 5 * 1000
              });
              // console.log(err)
            }).finally(() => {
              this.loading = false;
            });
          }
        }
      });
    },
    onSubmit() {
      this.$refs.form.validate(valid => {
        if (!valid) {
          return false;
        } else {
          this.loading = true;
          if (this.$route.params.id) {
            (0, _moomoomember.editPasskitPoint)(this.moomoomember).then(response => {
              (0, _elementUi.Message)({
                dangerouslyUseHTMLString: true,
                message: 'The final point ' + response.final_point + ' is  updated.',
                type: 'success',
                duration: 5 * 1000
              });
              this.$router.push(`/moomoomembers`);
            }).catch(err => {
              (0, _elementUi.Message)({
                dangerouslyUseHTMLString: true,
                message: (0, _errorParse.parseError)(err) || 'Update failed, please retry.',
                type: 'error',
                duration: 5 * 1000
              });
              // console.log(err)
              this.loading = false;
            });
          }
        }
      });
    },
    onCancel() {
      this.$router.push(`/moomoomembers`);
    }
  }
};
exports.default = _default;