"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _Pagination = _interopRequireDefault(require("@/components/Pagination"));
var _vuex = require("vuex");
var _errorParse = require("@/utils/error-parse");
var _purchaseRequest = require("@/api/purchaseRequest");
var _elementUi = require("element-ui");
var _store = require("@/api/store");
var _i18n = _interopRequireDefault(require("@/i18n"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    Pagination: _Pagination.default
  },
  /* filters: {
    statusFilter(status) {
      const statusMap = {
        published: 'success',
        draft: 'gray',
        deleted: 'danger'
      }
      return statusMap[status]
    }
  }, */
  data() {
    return {
      activeName: null,
      allEnable: false,
      summaryVisible: false,
      summary: null,
      list: null,
      listLoading: false,
      listQuery: {
        page: 1,
        limit: 10,
        listAll: true,
        status: null,
        targetRestockDate: null,
        stores: null
      },
      total: 0,
      stores: '',
      statusByOptions: [{
        key: 'drafted',
        name: 'Drafted'
      }, {
        key: 'submitted',
        name: 'Submitted'
      }, {
        key: 'rejected',
        name: 'Rejected'
      }, {
        key: 'confirmed',
        name: 'Confirmed'
      }, {
        key: 'completed',
        name: 'Completed'
      }]
    };
  },
  computed: {
    device() {
      return this.$store.state.app.device;
    },
    ...(0, _vuex.mapGetters)(['user'])
  },
  async created() {
    // this.fetchData()

    (0, _store.getStores)({
      limit: 999
    }).then(response => {
      this.stores = response.data;
    });
    if (this.$route.params.pathMatch) {
      this.activeName = this.$route.params.pathMatch;
      this.handleTabClick({
        name: this.$route.params.pathMatch
      });
    } else {
      this.activeName = 'all';
      this.handleTabClick({
        name: 'all'
      });
    }
  },
  methods: {
    toggleAllEnable() {
      if (this.allEnable) {
        this.list.forEach(row => {
          if (row.status === 'Confirmed' && !(row.purchaseOrders && row.purchaseOrders.length)) {
            row.isEnabled = false;
          } else {
            row.isEnabled = false;
          }
        });
        this.allEnable = false;
      } else {
        this.list.forEach(row => {
          row.isEnabled = row.status === 'Confirmed' && !(row.purchaseOrders && row.purchaseOrders.length);
        });
        this.allEnable = true;
      }
    },
    previewData() {
      const purchaseData = this.list;
      // const ids = ''
      const selectedPurchaseOrders = [];
      purchaseData.forEach(p => {
        if (p.isEnabled) {
          selectedPurchaseOrders.push(p);
        }
      });
      this.summary = {};
      let hasItem = false;
      selectedPurchaseOrders.forEach(purchaseOrder => {
        purchaseOrder.items.forEach(item => {
          let supplierId = -1;
          if (item.rawMaterial.supplier) {
            supplierId = item.rawMaterial.supplier.uuid;
          }
          if (!this.summary[supplierId]) {
            this.summary[supplierId] = {
              name: item.rawMaterial.supplier ? item.rawMaterial.supplier.name : 'No Name',
              items: {}
            };
          }
          if (!this.summary[supplierId].items[item.rawMaterial.uuid]) {
            this.summary[supplierId].items[item.rawMaterial.uuid] = {
              sku: item.rawMaterial.sku,
              name: item.rawMaterial.nameEng,
              unit: item.rawMaterial.unit,
              unitPrice: item.rawMaterial.defaultPrice,
              qty: 0
            };
          }
          this.summary[supplierId].items[item.rawMaterial.uuid].qty += item.qty;
          hasItem = true;
        });
      });
      for (const i in this.summary) {
        this.summary[i].items = Object.values(this.summary[i].items);
        this.summary[i].total = 0;
        this.summary[i].items.forEach(item => {
          this.summary[i].total += item.unitPrice.toFixed(2) * item.qty;
        });
      }
      if (hasItem) {
        this.summaryVisible = true;
      } else {
        this.$alert(_i18n.default.t('No Items, Please Select Item First'), 'Preview').catch(err => err === 'cancel' ? Promise.resolve('cancelled') : Promise.reject(err));
      }

      // console.log(selectedPurchaseOrders)

      // previewPurchaseRequest(ids).then(response => {
      //   this.$alert(response, 'Preview Purchase Request', {
      //     dangerouslyUseHTMLString: true
      //   })
      // })
    },

    fetchData() {
      this.listLoading = true;
      (0, _purchaseRequest.getList)(this.listQuery).then(response => {
        this.list = Object.freeze(response.data.map(row => ({
          ...row,
          isEnabled: false
        })));
        this.total = response.meta.total;
      }).finally(() => {
        this.listLoading = false;
      });
    },
    editPurchaseRequest(id) {
      this.$router.push(`/purchase-requests/edit/${id}`);
    },
    addPurchaseRequest() {
      this.$router.push(`/purchase-requests/add`);
    },
    deletePurchaseRequest(id) {
      return _elementUi.MessageBox.confirm('Confirm to delete record?', 'Confirm', {
        confirmButtonText: _i18n.default.t('PurchaseRequests.Delete'),
        cancelButtonText: _i18n.default.t('PurchaseRequests.Cancel'),
        type: 'warning'
      }).then(() => (0, _purchaseRequest.deletePurchaseRequest)(id).then(() => {
        (0, _elementUi.Message)({
          message: _i18n.default.t('PurchaseRequests.RecordDeleted'),
          type: 'success',
          duration: 5 * 1000
        });
        return this.fetchData();
      }).catch(err => (0, _elementUi.Message)({
        dangerouslyUseHTMLString: true,
        message: (0, _errorParse.parseError)(err) || 'Delete failed, please retry.',
        type: 'error',
        duration: 5 * 1000
      }))).catch(err => err === 'cancel' ? Promise.resolve('cancelled') : Promise.reject(err));
    },
    generatePO() {
      return _elementUi.MessageBox.confirm(_i18n.default.t('PurchaseRequests.ConfirmToGeneratePO'), 'Confirm', {
        confirmButtonText: _i18n.default.t('PurchaseRequests.Generate'),
        cancelButtonText: _i18n.default.t('PurchaseRequests.ConfirmToGeneratePO'),
        type: 'warning'
      }).then(() => {
        const uuids = [];
        this.list.forEach(row => {
          if (row.isEnabled) {
            uuids.push(row.uuid);
          }
        });
        return (0, _purchaseRequest.generatePurchaseOrderFromPurchaseRequest)(uuids).then(response => {
          for (const item of response) {
            (0, _elementUi.Message)({
              message: _i18n.default.t('PurchaseRequests.POGenerated') + item.displayId,
              type: 'success',
              duration: 5 * 1000
            });
          }
          this.$router.push(`/purchase-orders/confirmed`);
        }).catch(err => {
          (0, _elementUi.Message)({
            message: _i18n.default.t('PurchaseRequests.CantGeneratePOGenerated') + ' ' + (err.message ? err.message : ''),
            type: 'danger',
            duration: 5 * 1000
          });
        });
      }).catch(err => err === 'cancel' ? Promise.resolve('cancelled') : Promise.reject(err));
    },
    handleTabClick(tab) {
      let targetStatus = this.listQuery.status;
      if (tab.name === 'all') {
        targetStatus = null;
      } else if (tab.name === 'drafted') {
        targetStatus = 'Drafted';
      } else if (tab.name === 'submitted') {
        targetStatus = 'Submitted';
      } else if (tab.name === 'confirmed') {
        targetStatus = 'Confirmed';
      } else if (tab.name === 'completed') {
        targetStatus = 'Completed';
      } else {
        targetStatus = null;
      }
      if (targetStatus !== this.listQuery.status) {
        this.listQuery.status = targetStatus;
        this.fetchData();
      }
      this.$router.push(`/purchase-requests/` + tab.name);
    }
  }
};
exports.default = _default;