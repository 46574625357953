"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _errorParse = require("@/utils/error-parse");
var _elementUi = require("element-ui");
var _vuex = require("vuex");
var _i18n = _interopRequireDefault(require("@/i18n"));
var _purchaseOrder = require("@/api/purchaseOrder");
var _supplier = require("@/api/supplier");
var _store = require("@/api/store");
var _user = require("@/api/user");
var _addItemDialog = _interopRequireDefault(require("./add-item-dialog"));
var _copyItemDialog = _interopRequireDefault(require("./copy-item-dialog"));
var _vueSelect = _interopRequireDefault(require("vue-select"));
require("vue-select/src/scss/vue-select.scss");
var _scrollIntoViewIfNeeded = _interopRequireDefault(require("scroll-into-view-if-needed"));
var _pluralize = _interopRequireDefault(require("pluralize"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    vSelect: _vueSelect.default,
    AddItemDialog: _addItemDialog.default,
    CopyItemDialog: _copyItemDialog.default
  },
  data() {
    const validateItem = (rule, items, callback) => {
      if (!items.filter(item => item.qty).length) {
        callback(new Error('Purchasing need Item'));
      } else {
        callback();
      }
    };
    return {
      loading: false,
      labelPosition: window.innerWidth > 690 ? 'left' : 'top',
      suppliers: [],
      materials: '',
      createMode: true,
      dialogFormVisible: false,
      dialogCopyFormVisible: false,
      purchaseOrder: {
        id: '',
        displayId: '',
        status: 'Drafted',
        number: '',
        storeId: null,
        remark: '',
        items: [],
        contactName: '',
        contactEmail: '',
        contactTel: '',
        poDate: '',
        deliveryAddress: '',
        supplierContactName: '',
        supplierContactEmail: '',
        supplierContactTel: ''
      },
      disabled: 0,
      buttonText: _i18n.default.t('PurchaseOrders.Save'),
      buttonSubmit: _i18n.default.t('PurchaseOrders.buttonSubmit'),
      buttonDelete: _i18n.default.t('PurchaseOrders.buttonDelete'),
      buttonUnsubmit: _i18n.default.t('PurchaseOrders.buttonUnsubmit'),
      buttonConfirm: _i18n.default.t('PurchaseOrders.buttonConfirm'),
      buttonReject: _i18n.default.t('PurchaseOrders.buttonReject'),
      buttonComplete: _i18n.default.t('PurchaseOrders.buttonComplete'),
      buttonDownload: _i18n.default.t('PurchaseOrders.buttonDownload'),
      buttonGenerateGR: _i18n.default.t('PurchaseOrders.buttonGenerateGR'),
      buttonGenerateGC: _i18n.default.t('PurchaseOrders.buttonGenerateGC'),
      rules: {
        status: [{
          required: true,
          message: _i18n.default.t('PurchaseOrders.RequiredMessage'),
          trigger: 'change'
        }],
        items: [{
          required: true,
          message: 'Item qty is required',
          validator: validateItem,
          trigger: 'change'
        }],
        item: [{
          required: true,
          validator(rule, value, callback) {
            if (value && !Number.isNaN(Number(value)) && Number(value) > 0) {
              callback();
            } else {
              callback(new Error('Please fill the allocation qty below'));
            }
          },
          message: 'Item qty is required',
          trigger: 'change'
        }],
        supplierId: [{
          required: true,
          message: _i18n.default.t('PurchaseOrders.RequiredMessage')
        }],
        deliveryAddress: [{
          required: true,
          message: _i18n.default.t('PurchaseOrders.RequiredMessage')
        }]
      },
      options: [],
      stores: [],
      roleNames: []
    };
  },
  computed: {
    ...(0, _vuex.mapGetters)(['roles']),
    canConfirm: {
      get() {
        return this.roleNames && (this.roleNames.includes('Admin') || this.roleNames.includes('Procurement Manager'));
      }
    },
    env: {
      get() {
        return process.env.NODE_ENV;
      }
    },
    windowWidth: {
      get() {
        return window.innerWidth;
      }
    },
    drafted: {
      get() {
        return this.purchaseOrder && this.purchaseOrder.status === 'Drafted';
      }
    },
    confirmed: {
      get() {
        return this.purchaseOrder && this.purchaseOrder.status === 'Confirmed';
      }
    },
    outstanding: {
      get() {
        return this.purchaseOrder && this.purchaseOrder.status === 'Outstanding';
      }
    },
    completed: {
      get() {
        return this.purchaseOrder && this.purchaseOrder.status === 'Completed';
      }
    },
    total: {
      get() {
        let total = 0;
        if (this.purchaseOrder.goodReceivedNotes && this.purchaseOrder.goodReceivedNotes.length > 0) {
          this.purchaseOrder.items.forEach(item => {
            total += item.unitPrice.toFixed(2) * (item.receivedAmount + item.canceledAmount);
          });
        } else {
          this.purchaseOrder.items.forEach(item => {
            total += item.unitPrice.toFixed(2) * item.qty;
          });
        }
        return total;
      }
    },
    purchaseOrderSupplier: {
      get() {
        if (this.purchaseOrder && this.purchaseOrder.supplierId) {
          return this.suppliers.find(supplier => supplier.uuid === this.purchaseOrder.supplierId);
        }
        return null;
      }
    }
  },
  watch: {
    windowWidth(newWidth, oldWidth) {
      if (newWidth < 690 && oldWidth > 690) {
        this.labelPosition = 'top';
      } else if (newWidth > 690 && oldWidth < 690) {
        this.labelPosition = 'left';
      }
    },
    purchaseOrderSupplier() {
      if (this.createMode) {
        if (this.purchaseOrderSupplier) {
          this.purchaseOrder.supplierContactName = this.purchaseOrderSupplier.contactName;
          this.purchaseOrder.supplierContactEmail = this.purchaseOrderSupplier.email;
          this.purchaseOrder.supplierContactTel = this.purchaseOrderSupplier.tel;
        }
      }
    },
    purchaseOrder: {
      handler() {
        if (this.createMode) {
          this.purchaseOrder.items.forEach(item => {
            let count = 0;
            if (item.allocations) {
              item.allocations.forEach(allocation => {
                count += allocation.qty;
              });
            }
            item.qty = count;
          });
        }
      },
      deep: true
    }
  },
  async created() {
    await (0, _store.getStores)({
      perPage: 999
    }).then(response => {
      this.stores = response.data;
    });
    let promise = Promise.resolve();
    if (this.$route.params.id) {
      // this.buttonText = 'Save'
      this.disabled = 1;
      promise = this.fetchData();
      this.createMode = false;
    } else {
      this.createMode = true;
      // this.buttonText = 'Save'
    }

    this.roleNames = this.roles.map(role => role.name);
    if (this.createMode) {
      (0, _user.getInfo)(this.$route.params.id).then(response => {
        this.user = response.data;
        this.purchaseOrder.contactName = this.user.name;
        this.purchaseOrder.contactEmail = this.user.email;
        this.purchaseOrder.contactTel = this.user.tel;
        this.purchaseOrder.poDate = new Date();
      });
    }
    promise.then(() => {
      if (this.confirmed || this.outstanding || this.completed) {
        this.suppliers = [this.purchaseOrder.supplier];
      } else {
        (0, _supplier.getSuppliers)({
          perPage: 999
        }).then(response => {
          this.suppliers = response.data;
        });
      }

      /* if(!this.completed) {
        this.purchaseOrder.deliveryAddress = '5/F, Hung To Restaurant, 30 Hung To Road, Kwun Tong'
      } else {
        delete this.rules.deliveryAddress
      } */
    });
  },

  methods: {
    requestedTotal(item) {
      let count = 0;
      item.allocations.forEach(allocation => {
        count += allocation.qty;
      });
      return count;
    },
    handleAddItemDialogComplete(rawMaterials) {
      rawMaterials.forEach(rawMaterial => {
        const item = {};
        item.rawMaterial = rawMaterial;
        item.qty = 0;
        item.unitPrice = rawMaterial.defaultPrice;
        item.allocations = [];
        this.stores.forEach(store => {
          item.allocations.push({
            store,
            qty: 0
          });
        });
        item.remark = null;
        this.purchaseOrder.items.push(item);
        this.dialogFormVisible = false;
      });
    },
    handleCopyItemDialogComplete(rawMaterial) {
      /* const item = {}
      item.rawMaterial = rawMaterial
      item.qty = 0
      item.unitPrice = rawMaterial.defaultPrice
      item.remark = null
      this.purchaseOrder.items.push(item) */
      this.dialogCopyFormVisible = false;
    },
    addRow() {
      this.dialogFormVisible = true;
    },
    generateText() {
      this.dialogCopyFormVisible = true;
    },
    pluralize(unit) {
      if (['kg', 'gr', 'l', 'ml', 'mg'].indexOf(unit) === -1) {
        return (0, _pluralize.default)(unit);
      } else {
        return unit;
      }
    },
    fetchData() {
      this.loading = true;
      return (0, _purchaseOrder.getPurchaseOrder)(this.$route.params.id).then(response => {
        if (response.data.status !== 'Completed' && response.data.status !== 'Outstanding' && !response.data.deliveryAddress) {
          response.data.deliveryAddress = '5/F, Hung To Restaurant, 30 Hung To Road, Kwun Tong';
        }
        this.purchaseOrder = response.data;
        this.purchaseOrderNumber = response.data.displayId;
        this.purchaseOrder.supplierId = response.data.supplier.uuid;
        this.purchaseOrder.items.forEach(item => {
          item.allocations = [];
          this.stores.forEach(store => {
            let qty = 0;
            this.purchaseOrder.purchaseRequests.forEach(purchaseRequest => {
              if (purchaseRequest.store.uuid === store.uuid) {
                purchaseRequest.items.forEach(purchaseRequestItem => {
                  if (purchaseRequestItem.rawMaterial.uuid === item.rawMaterial.uuid) {
                    qty += purchaseRequestItem.qty;
                  }
                });
              }
            });
            item.allocations.push({
              store,
              qty: qty
            });
          });
        });
      }).catch(error => console.error(error)).finally(() => {
        this.loading = false;
      });
    },
    handleDelete(index) {
      this.purchaseOrder.items.splice(index, 1);
    },
    onSubmit() {
      return this.$refs.form.validate((valid, invalidFields) => {
        if (!valid) {
          console.log(this.$refs.form.fields, Object.values(invalidFields)[0], Object.values(invalidFields)[0][0].field);
          this.$refs.form.fields.map(field => field.validate('', (message, fieldRule) => {
            if (message) {
              console.log(message, fieldRule);
              (0, _scrollIntoViewIfNeeded.default)(field.$el, {
                scrollMode: 'if-needed',
                behavior: 'smooth',
                block: 'start'
              });
            }
          })).reduce(function (cur, next) {
            return cur.then(next);
          }, Promise.resolve());
          return false;
        }
        this.submit().then(response => {
          if (this.env !== 'development') {
            if (this.$route.params.id) {
              if (this.$workbox && this.$workbox.active instanceof Promise) {
                const uniqueValue = window['Symbol'] ? Symbol('unique') : /unique/;
                return Promise.race([this.$workbox.active, Promise.resolve(uniqueValue)]).then(value => {
                  if (value !== uniqueValue) {
                    return (0, _purchaseOrder.getPurchaseOrder)(this.$route.params.id).then(() => {
                      this.$router.go();
                    });
                  } else {
                    this.$router.go();
                  }
                }, () => {
                  this.$router.go();
                });
              } else {
                this.$router.go();
              }
            } else {
              this.$router.push(`/purchase-orders/edit/` + response.data.uuid);
            }
          }
        });
      });
    },
    submit() {
      this.loading = true;
      if (this.$route.params.id) {
        return (0, _purchaseOrder.editPurchaseOrder)(this.purchaseOrder).then(response => {
          (0, _elementUi.Message)({
            message: _i18n.default.t('PurchaseOrders.POUpdated'),
            type: 'success',
            duration: 5 * 1000
          });
          if (this.$workbox && this.$workbox.active instanceof Promise) {
            const uniqueValue = window['Symbol'] ? Symbol('unique') : /unique/;
            return Promise.race([this.$workbox.active, Promise.resolve(uniqueValue)]).then(value => {
              if (value !== uniqueValue) {
                return (0, _purchaseOrder.getPurchaseOrder)(this.$route.params.id).then(() => response);
              } else {
                return response;
              }
            }, () => {
              return response;
            });
          } else {
            return response;
          }
          // return response
        }).catch(err => {
          (0, _elementUi.Message)({
            dangerouslyUseHTMLString: true,
            message: (0, _errorParse.parseError)(err) || _i18n.default.t('PurchaseOrders.UpdateFailed'),
            type: 'error',
            duration: 5 * 1000
          });
          // console.log(err)
        }).finally(() => {
          this.loading = false;
        });
      } else {
        return (0, _purchaseOrder.createPurchaseOrder)(this.purchaseOrder).then(response => {
          (0, _elementUi.Message)({
            message: _i18n.default.t('PurchaseOrders.POCreated'),
            type: 'success',
            duration: 5 * 1000
          });
          return response;
        }).catch(err => {
          (0, _elementUi.Message)({
            dangerouslyUseHTMLString: true,
            message: (0, _errorParse.parseError)(err) || _i18n.default.t('PurchaseOrders.CreateFailed'),
            type: 'error',
            duration: 5 * 1000
          });
        }).finally(() => {
          this.loading = false;
        });
      }
    },
    async deletePurchaseOrder() {
      return _elementUi.MessageBox.confirm(_i18n.default.t('PurchaseOrders.ConfirmDelete'), 'Confirm', {
        confirmButtonText: _i18n.default.t('PurchaseOrders.Delete'),
        cancelButtonText: _i18n.default.t('PurchaseOrders.Cancel'),
        type: 'warning'
      }).then(() => (0, _purchaseOrder.deletePurchaseOrder)(this.purchaseOrder.uuid).then(() => {
        const prefix1 = `${process.env.VUE_APP_BASE_API}/api/v1/purchase-orders?listAll=true&status=${this.purchaseOrder.status}`;
        const prefix2 = `${process.env.VUE_APP_BASE_API}/api/v1/purchase-orders?listAll=true&page=1`;
        const prefix3 = `/api/v1/purchase-orders?listAll=true&status=${this.purchaseOrder.status}`;
        const prefix4 = `/api/v1/purchase-orders?listAll=true&page=1`;
        return caches.open('data').then(cache => {
          return cache.keys().then(cacheKeys => {
            const matchingKeys = cacheKeys.filter(key => key.url.startsWith(prefix1) || key.url.startsWith(prefix2) || key.url.startsWith(prefix3) || key.url.startsWith(prefix4));
            const deletePromises = matchingKeys.map(key => cache.delete(key));
            return Promise.all(deletePromises).catch(error => {
              console.error('An error occurred while removing cache entries:', error);
            });
          }).catch(error => {
            console.error('An error occurred while retrieving cache keys:', error);
          });
        }).finally(() => {
          (0, _elementUi.Message)({
            message: 'Record deleted',
            type: 'success',
            duration: 5 * 1000
          });
          this.$router.push(`/purchase-orders`);
        });
      }).catch(err => err.status === 404 ? Promise.resolve('already deleted') : Promise.reject(err)).catch(err => {
        (0, _elementUi.Message)({
          dangerouslyUseHTMLString: true,
          message: (0, _errorParse.parseError)(err) || _i18n.default.t('PurchaseOrders.DeleteFailed'),
          type: 'error',
          duration: 5 * 1000
        });
      })).catch(err => err === 'cancel' ? Promise.resolve('cancelled') : Promise.reject(err));
    },
    async confirmPurchaseOrder() {
      return _elementUi.MessageBox.confirm(_i18n.default.t('PurchaseOrders.ConfirmRequest'), 'Confirm', {
        confirmButtonText: _i18n.default.t('PurchaseOrders.Confirm'),
        cancelButtonText: _i18n.default.t('PurchaseOrders.Cancel'),
        type: 'warning'
      }).then(() => (0, _purchaseOrder.editPurchaseOrder)(this.purchaseOrder).then(() => (0, _purchaseOrder.confirmPurchaseOrder)(this.purchaseOrder.uuid).then(() => {
        (0, _elementUi.Message)({
          message: 'Request confirmed',
          type: 'success',
          duration: 5 * 1000
        });
        if (this.$workbox && this.$workbox.active instanceof Promise) {
          const uniqueValue = window['Symbol'] ? Symbol('unique') : /unique/;
          return Promise.race([this.$workbox.active, Promise.resolve(uniqueValue)]).then(value => {
            if (value !== uniqueValue) {
              return (0, _purchaseOrder.getList)({
                page: 1,
                perPage: 10,
                limit: 10,
                listAll: true,
                status: 'confirmed',
                supplierId: null,
                suppliers: null,
                targetRestockDate: null,
                poDate: null,
                storeId: null,
                search: null,
                fromTime: '',
                toTime: ''
              }).then(() => {
                return this.$router.push(`/purchase-orders/confirmed`);
              });
            } else {
              return this.$router.push(`/purchase-orders/confirmed`);
            }
          }, () => {
            return this.$router.push(`/purchase-orders/confirmed`);
          });
        } else {
          return this.$router.push(`/purchase-orders/confirmed`);
        }
      }).catch(err => {
        // @TODO why need to delete
        (0, _elementUi.Message)({
          dangerouslyUseHTMLString: true,
          message: (0, _errorParse.parseError)(err) || _i18n.default.t('PurchaseOrders.DeleteFailed'),
          type: 'error',
          duration: 5 * 1000
        });
      })).catch(err => {
        (0, _elementUi.Message)({
          dangerouslyUseHTMLString: true,
          message: (0, _errorParse.parseError)(err) || _i18n.default.t('PurchaseOrders.ConfirmFailed'),
          type: 'error',
          duration: 5 * 1000
        });
      })).catch(err => err === 'cancel' ? Promise.resolve('cancelled') : Promise.reject(err));
    },
    async generateGRFromPurchaseOrder() {
      return _elementUi.MessageBox.confirm('Confirm to generate Good Received Note?', 'Confirm', {
        confirmButtonText: _i18n.default.t('PurchaseOrders.Confirm'),
        cancelButtonText: _i18n.default.t('PurchaseOrders.Cancel'),
        type: 'warning'
      }).then(() => (0, _purchaseOrder.editPurchaseOrder)(this.purchaseOrder).then(() => (0, _purchaseOrder.generateGRFromPurchaseOrder)(this.purchaseOrder.uuid).then(response => {
        (0, _elementUi.Message)({
          message: _i18n.default.t('PurchaseOrders.GRGenerated'),
          type: 'success',
          duration: 5 * 1000
        });
        return this.$router.push('/good-received-notes/edit/' + response.data.uuid);
      })).catch(err => {
        (0, _elementUi.Message)({
          dangerouslyUseHTMLString: true,
          message: (0, _errorParse.parseError)(err) || _i18n.default.t('PurchaseOrders.GRFailed'),
          type: 'error',
          duration: 5 * 1000
        });
      })).catch(err => err === 'cancel' ? Promise.resolve('cancelled') : Promise.reject(err));
    },
    generateGCFromPurchaseOrder() {
      return _elementUi.MessageBox.confirm('Confirm to cancel Amount?', 'Confirm', {
        confirmButtonText: _i18n.default.t('PurchaseOrders.Confirm'),
        cancelButtonText: _i18n.default.t('PurchaseOrders.Cancel'),
        type: 'warning'
      }).then(() => (0, _purchaseOrder.editPurchaseOrder)(this.purchaseOrder).then(() => (0, _purchaseOrder.generateGCFromPurchaseOrder)(this.purchaseOrder.uuid).then(response => {
        (0, _elementUi.Message)({
          message: _i18n.default.t('PurchaseOrders.GCGenerated'),
          type: 'success',
          duration: 5 * 1000
        });
        this.$router.push('/good-received-notes/edit/' + response.data.uuid);
      })).catch(err => {
        (0, _elementUi.Message)({
          dangerouslyUseHTMLString: true,
          message: (0, _errorParse.parseError)(err) || _i18n.default.t('PurchaseOrders.GCFailed'),
          type: 'error',
          duration: 5 * 1000
        });
      })).catch(err => err === 'cancel' ? Promise.resolve('cancelled') : Promise.reject(err));
    },
    onCancel() {
      if (this.purchaseOrder.status === 'Drafted') {
        this.$router.push(`/purchase-orders/drafted`);
      } else if (this.purchaseOrder.status === 'Confirmed') {
        this.$router.push(`/purchase-orders/confirmed`);
      } else if (this.purchaseOrder.status === 'Outstanding') {
        this.$router.push(`/purchase-orders/outstanding`);
      } else if (this.purchaseOrder.status === 'Completed') {
        this.$router.push(`/purchase-orders/completed`);
      } else {
        this.$router.push(`/purchase-orders/all`);
      }
    },
    recalculate(purchaseItem) {
      purchaseItem.qty = purchaseItem.allocations.reduce((a, allocation) => a + allocation.qty, 0);
    },
    async downloadPurchaseOrder(id, displayId) {
      this.$refs.form.validate(valid => {
        if (!valid) {
          return false;
        }
        // ui wont show downloadPDF for new PO
        this.submit().then(() => (0, _purchaseOrder.downloadPurchaseOrder)(id)).then(response => {
          const fileURL = window.URL.createObjectURL(response);
          const fileLink = document.createElement('a');
          fileLink.href = fileURL;
          fileLink.setAttribute('download', `${displayId}.pdf`);
          document.body.appendChild(fileLink);
          fileLink.click();
          this.$router.go();
        }).catch(err => {
          console.error(err);
          (0, _elementUi.Message)({
            dangerouslyUseHTMLString: true,
            message: (0, _errorParse.parseError)(err) || _i18n.default.t('PurchaseOrders.DownloadFailed'),
            type: 'error',
            duration: 5 * 1000
          });
        });
      });
    },
    async downloadRelatedPR(id, displayId) {
      (0, _purchaseOrder.downloadRelatedPR)(id).then(response => {
        const fileURL = window.URL.createObjectURL(response);
        const fileLink = document.createElement('a');
        fileLink.href = fileURL;
        fileLink.setAttribute('download', `${displayId}.zip`);
        document.body.appendChild(fileLink);
        fileLink.click();
      }).catch(err => {
        console.error(err);
        (0, _elementUi.Message)({
          dangerouslyUseHTMLString: true,
          message: (0, _errorParse.parseError)(err) || _i18n.default.t('PurchaseOrders.DownloadFailed'),
          type: 'error',
          duration: 5 * 1000
        });
      });
    }
  }
};
exports.default = _default;