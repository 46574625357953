"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _moomoomember = require("@/api/moomoomember");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  data() {
    return {
      loading: false,
      currentUser: null,
      moomoomember: {
        id: null,
        name: '',
        email: '',
        mobilephone: '',
        password: '',
        password_confirmation: ''
      },
      buttonText: 'Add',
      hrPerMonth: 0,
      rules: {
        name: {
          required: true,
          message: 'Name is required'
        },
        email: {
          required: true,
          message: 'Email is required'
        },
        mobilephone: {
          required: true,
          message: 'Mobile is required'
        }
      },
      isEdit: false
    };
  },
  async created() {
    if (this.$route.params.id) {
      this.buttonText = 'Edit';
      this.isEdit = true;
      this.fetchData();
    } else {
      this.buttonText = 'Add';
      this.$set(this.rules, 'password', [{
        required: true,
        message: 'Password is required',
        trigger: 'blur'
      }, {
        min: 6,
        message: 'Password can not be less than 6 digits',
        trigger: 'blur'
      }]);
    }
  },
  methods: {
    async fetchData() {
      this.loading = true;
      (0, _moomoomember.getMOOMOOMember)(this.$route.params.id).then(response => {
        this.moomoomember = response.data;
      }).finally(() => {
        this.loading = false;
      });
    }
  }
};
exports.default = _default;